import { message } from "antd";
import redux from '@/redux/store'
import AJAX from '@/api/api'
import CookieUtils from '@/utils/cookie'


export default function (e) {
    let urls = 'http://localhost:9103/#/login?isLogin=0';
    let hosts = window.location.host;
    if (hosts.indexOf('513online') != -1) urls = 'http://enterprise.513online.top/#/login?isLogin=0';
    if (hosts.indexOf('edu513') != -1) urls = 'https://pin.513.com/#/login?isLogin=0';
    AJAX.login.logOut().then(res => {
        localStorage.clear()
        CookieUtils.setCookie("sessionEnterpriseId", "", -1)
        CookieUtils.setCookie("sessionEnterpriseName", "", -1)
        CookieUtils.setCookie("sessionUserId", "", -1)
        if (e) {
            message.error("登录已过期，请重新登录", 1, () => {
                window.location = urls;
            })
        } else {
            message.success("退出登录成功，请重新登录", 1, () => {
                window.location = urls;
            })
        }
    })
}