import Ajax from "./request"

export default {
    login:{
        signIn(params){  // 登录
            return Ajax.ajaxs("/user/login", params);
        },
        signUp(params){   // 注册
            return Ajax.ajaxs("/user/register", params);
        },
        getBackPassword(params){   // 找回密码
            return Ajax.ajaxs("/user/reset/password", params);
        },
        getCodeImgUrl(){   // 图片验证码
            return Ajax.ajaxs("/verifyCode", {},'GET');
        },
        getPhoneCode(params){   //短信验证码
            return Ajax.ajaxs("/sendMobileCode", params);
        },
        logOut(){              //退出登录
            return Ajax.ajaxs("/user/logout", {});
        },
        getUserInfo(params){   //获取用户信息
            return Ajax.ajaxs("/user/getUserInfo",params)
        }
    },
    tags:{
        getCityTags(){//城市列表
            return Ajax.ajaxs("/tag/selectCityInfo",{},'GET');
        },
        getPostList(){//获取职位列表
            return Ajax.ajaxs("/tag/selectPositionInfo",{},'GET');
        },
        getEducateList(){//获取学历列表
            return Ajax.ajaxs("/tag/selectEducateInfo",{},'GET');
        },
        getIndustryList(){ //行业列表
            return Ajax.ajaxs("/tag/selectTradeInfo", {},'GET');
        },
        getNatureList(){ //企业性质
            return Ajax.ajaxs("/tag/selectNatureInfo", {},'GET');
        },
        getFirmSizeList(){ //公司规模
            return Ajax.ajaxs("/tag/selectFirmSizeInfo", {},'GET');
        },
        getFinancingList(){ //融资阶段
            return Ajax.ajaxs("/tag/selectFinancingInfo", {},'GET');
        },
        getWelfareList(){ //福利标签
            return Ajax.ajaxs("/tag/selectWelfareInfo", {},'GET');
        },
        getExperienceList(){ //工作经验标签
            return Ajax.ajaxs("/tag/selectWorkExperienceInfo", {},'GET');
        },
        getWorkTypeList(){ //工作性质
            return Ajax.ajaxs("/tag/selectWorkTypeInfo", {},'GET');
        },
        getSkillList({code}){ //技能标签
            return Ajax.ajaxs(`/tag/selectSkillInfo?code=${code}`, {},'GET');
        },
    },
    post:{
        getRecruitmentList(params){//获取企业职位列表
            return Ajax.ajaxs("/recruitment/getRecruitmentList",params,'GET');
        },
        addPost(params){//添加职位
            return Ajax.ajaxs("/recruitment/add", params);
        },
        editPost(params){//编辑职位
            return Ajax.ajaxs("/recruitment/update", params);
        },
        updatePostStatus({recruitmentId,state}){//更新职位状态
            return Ajax.ajaxs(`/recruitment/updateState?recruitmentId=${recruitmentId}&state=${state}`, {});
        },
        updatePost(params){//更新职位状态
            return Ajax.ajaxs(`/recruitment/update`, params);
        },
        getSendList(params){//投递列表
            return Ajax.ajaxs(`/send/getRecruitmentSendList`, params,"GET");
        },
        getBrowseList(params){//查看列表
            return Ajax.ajaxs(`/browse/getRecruitmentSendList`, params,"GET");
        },
        getRecruitmentListById(params){//根据id获取职位信息
            return Ajax.ajaxs(`/recruitment/getRecruitmentListId`, params,"GET");
        },
        extractSkillTag(params){//提取技能标签
            return Ajax.ajaxs(`/recruitment/extractSkillTag`, params,"POST");
        }
    },
    companyInfo:{
        getBaseInfo(){ //公司基本信息
            return Ajax.ajaxs("/enterpriseInfo/getEnterpriseInfoVo", {},'GET');
        },
        getBaseList(props){ //获取公司列表
            return Ajax.ajaxs("/enterpriseInfo/getEnterpriseIdNameAndUserIdVo", props,'POST');
        },
        getEnterpriseRatio(params){//公司信息完善度
            return Ajax.ajaxs("/enterpriseInfo/getEnterpriseRatio", {},'GET');
        },
        getEnterpriseContent(params){//公司介绍
            return Ajax.ajaxs("/enterpriseInfo/getEnterpriseContent", {},'GET');
        },
        getEnterpriseWelfare(params){//公司福利
            return Ajax.ajaxs("/enterpriseInfo/getEnterpriseWelfare", {},'GET');
        },
        uploadLogo(params){ //上传logo
            return Ajax.uploadFile("/file/upload", params);
        },
        saveBaseInfo(params){//保存基本信息
            return Ajax.ajaxs("/enterpriseInfo/modifyEnterpriseInfo", params,'PUT');
        },
        saveEnterpriseContent(params){//保存公司介绍
            return Ajax.ajaxs("/enterpriseInfo/modifyEnterpriseContent", params,'PUT');
        },
        saveWelfareTags({enterpriseId,welfareTag}){//保存福利标签
            return Ajax.ajaxs(`/enterpriseInfo/addWelfareList?enterpriseId=${enterpriseId}&welfareTag=${welfareTag}`, {});
        },
        delWelfareTag({enterpriseId,pathCode}){//删除单个福利标签
            return Ajax.ajaxs(`/enterpriseInfo/deleteWelfare?enterpriseId=${enterpriseId}&welfareTag=${pathCode}`, {}, 'DELETE');
        },
        updateWorkTime({enterpriseId,startTime,endTime}){//更新工作时间
            return Ajax.ajaxs(`/enterpriseInfo/modifyEnterpriseWorkTime?enterpriseId=${enterpriseId}&startTime=${startTime}&endTime=${endTime}`, {}, 'PUT');
        },
        updateRestTime({enterpriseId,restStipulate}){//更新休息时间
            return Ajax.ajaxs(`/enterpriseInfo/modifyEnterpriseRestStipulate?enterpriseId=${enterpriseId}&restStipulate=${restStipulate}`, {}, 'PUT');
        },
        updateOvertime({enterpriseId,overtime}){//更新加班情况
            return Ajax.ajaxs(`/enterpriseInfo/modifyEnterpriseOvertime?enterpriseId=${enterpriseId}&overtime=${overtime}`, {}, 'PUT');
        },
        getPhotos(){//公司相册数据
            return Ajax.ajaxs(`/enterpriseInfo/getEnterpriseAlbum`, {}, 'GET');
        },
        uploadPhoto(params){//上传公司相册图片
            // return Ajax.ajaxs(`/enterpriseInfo/addAlbum`, params)
            return Ajax.uploadFile("/file/upload", params);
        },
        savePhoto({enterpriseId,photoAlbum}){//上传公司相册图片
            return Ajax.ajaxs(`/enterpriseInfo/addAlbum?enterpriseId=${enterpriseId}&photoAlbum=${photoAlbum}`, {})
        },
        delPhoto({albumId}){//删除公司相册图片
            return Ajax.ajaxs(`/enterpriseInfo/deleteAlbum?albumId=${albumId}`, {}, 'DELETE')
        },
    }
}