import React from 'react';
import { Link } from 'react-router-dom';
import LoadableComponent from '@/utils/LoadableComponent'
import './contentMain.css'
import { Layout, Menu, Breadcrumb, ConfigProvider, Modal, Input, Select, message } from 'antd';
import loginOut from '@/utils/loginOut'
import '@/assets/font_icon/iconfont.css'
import CN from 'antd/es/locale/zh_CN';
import redux from '@/redux/store'
import menuList from './menuList'
import AJAX from "@/api/api";
import { stringify } from 'qs';

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
let Router = LoadableComponent(() => import('../Router'));
export const debounce = (fn, wait) => {
    var timeout = null;
    return function (e) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn.apply(this, arguments);
        }, wait);
    };
}
//三板块主页面
export default class ContentMain extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isshow: false,
            companyList: [],
            enterpriseId: '',
            enterpriseName: '',
            userId: '',
            pageSize: 30,
            pageNum: 1,
            scrollTop1: 0,
            inputValue: '',
            isClick: true
        };
        this.count = 0;
        this.breadcrumbData = [];
    }

    //检测导航栏
    detectionMenu = (menuList) => {
        let pathArr = this.props.location.pathname.split('/'),
            breadcrumbData = [];
        pathArr.shift();
        for (let item of menuList) {
            if (`/${pathArr[this.count]}` === item.path) {
                let to;
                let flag = this.breadcrumbData[this.count] && this.breadcrumbData[this.count].path === item.path
                if (flag) {
                    to = this.breadcrumbData[this.count].to;
                } else {
                    if (pathArr.length - 1 === this.count) {
                        let { pathname, search } = this.props.location;
                        to = `${pathname}${search}`
                    }
                }
                if (item.children) {
                    this.count++;
                    breadcrumbData.unshift(...this.detectionMenu(item.children))
                }
                breadcrumbData.unshift({ ...item, to });
                this.count = 0;
                return breadcrumbData;
            }
        }
        return []
    }

    //导航
    renderMenu = () => {
        let pathArr = this.props.location.pathname.split('/'),
            openKey = pathArr.slice(1, 2)[0],
            selectedKey = pathArr.slice(2, 3)[0];
        if (openKey === 'companyInfo') selectedKey = 'companyInfo'
        if (openKey === 'staff') selectedKey = 'staff'
        return <Menu className="teacherMenu" theme="dark" selectedKeys={[`/${selectedKey}`]} mode="inline" style={{ "background": "none" }} defaultOpenKeys={['/' + openKey]}>
            {menuList.map(item => {
                if (item.notRenderChild) {
                    return <Menu.Item key={item.path}
                        // icon={<span className={`iconfont ${item.icon}`}></span>}
                        onClick={() => { this.handleMenuClick(item.path, item.path) }}>
                        {item.text}
                    </Menu.Item>
                }
                return <SubMenu key={item.path} title={item.text}
                // icon={<span className={`iconfont ${item.icon}`}></span>}
                >
                    {item.children.map(list => {
                        return <Menu.Item key={list.path}
                            onClick={() => { this.handleMenuClick(list.path, item.path + list.path) }}
                        >
                            {list.text}
                        </Menu.Item>
                    })}
                </SubMenu>
            })}
        </Menu>
    }

    //面包屑的叠加
    renderNavList = () => {
        this.breadcrumbData = this.detectionMenu(menuList)
        return this.breadcrumbData.map((item, index) =>
            <Breadcrumb.Item key={index} style={item.to ? { cursor: 'pointer' } : {}} onClick={() => { this.breadcrumbClick(item) }}>{item.text}</Breadcrumb.Item>)
    }

    breadcrumbClick = (item) => {
        if (item.to) this.props.history.push(item.to);
    }

    //跳转页面
    handleMenuClick = (selectkey, path) => {
        this.setState({
            selectedKeys: [selectkey]
        }, () => {
            this.props.history.push(path);
        })
    }

    //确认切换企业按钮
    handleOk = () => {
        if (!this.state.enterpriseId || !this.state.userId) {
            message.error("请选择切换的公司")
            return false;
        }
        this.setState({
            isshow: false,
            scrollTop1: 0
        })
        document.cookie = "sessionEnterpriseId=" + this.state.enterpriseId;
        document.cookie = "sessionEnterpriseName=" + this.state.enterpriseName;
        document.cookie = "sessionUserId=" + this.state.userId;

        let companyInfo = JSON.parse(localStorage.getItem('companyInfo'))
        companyInfo.id = this.state.userId
        companyInfo.enterpriseId = this.state.enterpriseId
        companyInfo.name = this.state.enterpriseName

        redux.dispatch({ type: "companyInfo", payload: companyInfo })
        localStorage.setItem('companyInfo', JSON.stringify(companyInfo))
        this.props.history.push('/')
    }
    // 取消切换企业按钮
    handleCancel = () => {
        this.setState({
            isshow: false,
            pageNum: 1,
            scrollTop1: 0
        })
        this.refs.bodyBox.scrollTop = 0;
    }
    // 公司列表文本框发生变化时触发，获取id及name,e为选中下标
    setvalue = (e) => {
        this.setState({
            enterpriseName: this.state.companyList[e].enterpriseName,
            enterpriseId: this.state.companyList[e].enterpriseId,
            userId: this.state.companyList[e].userId
        })

    }
    // 切换公司按钮事件
    showbox = () => {
        AJAX.companyInfo.getBaseList({ pageSize: this.state.pageSize, pageNum: this.state.pageNum }).then((res) => {
            this.setState({
                isshow: true,
                companyList: res.data,
                scrollTop1: 0
            })
            this.refs.myInput.state.value = ""
            this.refs.myInput.onFocus()
        })
    }
    // 企业模糊查询事件
    inputChange = (type = '') => {
        if (type == 1) {
            this.setState({
                pageNum: 1
            })
        }
        AJAX.companyInfo.getBaseList({ enterpriseName: this.refs.myInput.state.value, pageSize: this.state.pageSize, pageNum: this.state.pageNum }).then((res) => {
            if (type == 1) {
                this.setState({
                    companyList: res.data
                })
            } else {
                this.setState({
                    companyList: this.state.companyList.concat(res.data)
                })
            }
        })
    }
    inputChange1 = () => {
        this.setState({
            pageNum: 1,
            scrollTop1: 0
        })
        this.inputChange(1)
    }
    // 公司切换事件
    swithCompany = (e) => {
        if (this.state.isClick == false) {
            return false;
        } else {
            this.setState({
                isClick: false
            })
        }
        document.cookie = "sessionEnterpriseId=" + this.state.companyList[e.target.getAttribute("data-index")].enterpriseId;
        document.cookie = "sessionEnterpriseName=" + this.state.companyList[e.target.getAttribute("data-index")].enterpriseName;
        if (this.state.companyList[e.target.getAttribute("data-index")].userId) document.cookie = "sessionUserId=" + this.state.companyList[e.target.getAttribute("data-index")].userId
        this.setState({
            isshow: false,
            isClick: true,
            pageNum: 1
        })
        message.success({
            content: "切换成功！",
            duration: 1
        })
        this.refs.bodyBox.scrollTop = 0;

        // 将新数据存本地localStorage中
        let companyInfo = JSON.parse(localStorage.getItem('companyInfo'))
        companyInfo.id = this.state.companyList[e.target.getAttribute("data-index")].userId;
        companyInfo.enterpriseId = this.state.companyList[e.target.getAttribute("data-index")].enterpriseId;
        companyInfo.name = this.state.companyList[e.target.getAttribute("data-index")].enterpriseName;
        redux.dispatch({ type: "companyInfo", payload: companyInfo })
        localStorage.setItem('companyInfo', JSON.stringify(companyInfo))
        this.props.history.push('/')
    }
    scrollList = debounce((e) => {
        let scrollTop = this.refs.bodyBox.scrollTop;  //滚动条滚动高度
        let scrollHeight = document.documentElement.scrollHeight; //滚动内容高度
        console.log("111")
        console.log(scrollTop, scrollHeight,this.state.scrollTop1)
        if (scrollTop > this.state.scrollTop1) {
            this.state.scrollTop1 = scrollTop
        } else {
            return false;
        }
        let clientHeight = document.documentElement.clientHeight; //可视区域高度
        if (scrollTop > scrollHeight - 50) {
            this.setState({
                pageNum: this.state.pageNum + 1
            })
            this.inputChange()
        }
    }, 500)
    render() {
        let { isshow, companyList, inputValue } = this.state;
        const { photo, schoolName, name, privilegeFlag } = redux.getState().companyInfo || {}
        // 循环弹框列表
        const companyLists = companyList.map((item, index) =>
            // <Option key={index} value={index}>{item.enterpriseName}</Option>  // 下拉框形式
            <p key={index} data-index={index} onClick={this.swithCompany}>{item.enterpriseName}</p>  //搜索框形式

        );
        return (<Layout className="ContentMain" style={{ minHeight: '100vh' }}>
            <Sider style={{ 'background': '#3E4459' }}>
                <div className="portrait">
                    <div className='headImg'>
                        <img src={photo ? photo : require('@/assets/images/public/head_default.png')}></img>
                    </div>
                    <span>{name}</span>
                    <div onClick={this.showbox} className={privilegeFlag ? 'swithImg' : 'swithImg ishide'}>切换企业</div>
                    <p>{schoolName}</p>
                </div>
                {this.renderMenu()}
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-sub-header-background" style={{ padding: 0 }} >
                    <div className="logo">
                        <a href="https://513.com" target="_blank">
                        <img src={require('@/assets/images/public/nav_logo.png')} />
                        </a>
                        <img src={require('@/assets/images/public/title.png')} />
                    </div>
                    <div className="userControl">
                        {/* <p>账户设置</p> */}
                        {/* <p>
                                <Link to="/managePositions/positionList/recruitment">招聘数据分析</Link>
                            </p> */}
                        <p onClick={() => loginOut(0)}>退出登录</p>
                    </div>
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb separator='>' style={{ margin: '16px 0' }}>
                        {this.renderNavList()}
                    </Breadcrumb>
                    <div className="site-layout-background" style={{ padding: '24px', minHeight: 'calc(100vh - 188px)', background: '#eef5f9' }}>
                        <ConfigProvider locale={CN}>
                            <Router />
                        </ConfigProvider>
                    </div>
                    <Footer style={{ textAlign: 'center' }}>Copyright ©深圳市伍壹叁教育科技有限公司 版权所有 粤ICP备19058570号-2</Footer>
                </Content>
            </Layout>
            {/* 切换企业弹框 */}
            <Modal title="切换企业" maskClosable={false} footer={null} visible={isshow} okText="" cancelText="" onOk={this.handleOk} onCancel={this.handleCancel}>
                {/* 下拉框形式 */}
                {/* <Input.Group compact>
                    <Select defaultValue="请选择切换的企业" onChange={this.setvalue} id="companyList">
                        {companyList}
                    </Select>
                </Input.Group> */}

                {/* 搜索形式 */}
                <div className="inputFlex" >
                    <Input placeholder="请输入您要切换的企业" type="text" ref="myInput" maxLength="50" onPressEnter={this.inputChange1} />
                    <div onClick={this.inputChange1} className="swithImg">搜索</div>
                </div>
                <div className={companyList.length > 0 ? 'searchList' : 'noSearchList empty'} onScroll={this.scrollList} ref="bodyBox">{companyList.length > 0 ? companyLists : '没有搜索到公司哦~~'}</div>
            </Modal>
        </Layout>)
    }
}















