import React, { useRef, useEffect, useCallback, useState } from "react";
import './App.css';
import { Route, Switch } from 'react-router-dom';
import ContentMain from '@/routes/contentMain/contentMain';
import Login from '@/view/login/login'
import getUrlData from "@/utils/getUrlData";
import AJAX from "@/api/api";
import redux from '@/redux/store'

function App() {
  let [isShow, setIsShow] = useState(false)
  useEffect(()=>{
    let t = getUrlData('t') ? getUrlData('t') : null;
    if (t) {
      getUserInfo(t);
    }else {
      console.log(12346)
      setIsShow(true)
    }
  })
  // 验证token,成功返回新token，存入cookies中
  let getUserInfo = useCallback((t) => {
    console.log(123)
    AJAX.login.getUserInfo({ authorizeToken: t }).then((res) => {
      if (res.code == 1) {
        redux.dispatch({ type: "companyInfo", payload: res.data })
        localStorage.setItem('companyInfo', JSON.stringify(res.data))
        setIsShow(true)
      }
    })
  }, [])
  return (
    <Switch>
      {isShow ?
        <><Route path="/login" component={Login}></Route>
          <Route path="/" component={ContentMain}></Route></>
         : null}
    </Switch>
  );
}

export default App;