const initValue = JSON.parse(localStorage.getItem('companyInfo'))
const companyInfo = (state = initValue,action) => {
    switch (action.type){
        case 'companyInfo':
            return action.payload
        default:
        return state
    }
}

export default companyInfo;