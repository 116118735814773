const menuList = [
    {
        // notRenderChild:true,
        icon: 'icon-xueshengguanli',
        text: "职位管理",
        path: '/managePositions',
        children:[
            {
                text: "职位列表",
                path: '/positionList',
                children:[
                    {
                        text: "发布职位",
                        path:'/publish',
                    },
                    {
                        text: "已投递的人才 ",
                        path:'/deliver',
                    },
                    {
                        text: "已查看的人才 ",
                        path:'/communicated',
                    },
                ]
            }
        ]
    },
    // {
    //     icon: 'icon-xueshengguanli',
    //     text: "职位搜索",
    //     path: '/search',
    //     children:[
    //         {
    //             text: "搜索人才",
    //             path:'/searchTalents',
    //         },
    //     ]
    // },
    {
        notRenderChild:true,
        icon: 'icon-xueshengguanli',
        text: "公司管理",
        path: '/companyInfo',
        children:[
            {
                text: "基本信息",
                path:'/baseInfo',
            },
            {
                text: "公司介绍",
                path:'/introduce',
            },
            {
                text: "公司福利",
                path:'/welfare',
            },
            {   
                text: "公司相册",
                path:'/photo',
            },
        ]
    },
    // {
    //     notRenderChild:true,
    //     icon: 'icon-xueshengguanli',
    //     text: "员工管理",
    //     path: '/staff',
    //     children:[
    //         {
    //             text: "操作日志",
    //             path:'/operatioLog',
    //         },
    //     ]
    // }
]

export default menuList;